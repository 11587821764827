<template>
    <div class="sign-box">
        <div class="header-hint">
            <div class="hint-list" v-if="scrollList.length">
                <ul ref="scroll" :style="{'left':left+'px'}">
                    <li v-for="(item,index) in scrollList" :key="index">{{item}}</li>
                </ul>
            </div>
            <div v-else></div>
            <div class="hint-btn" v-if="signArr.length" @click="ListDrawPrizeResFun">中奖记录</div>
        </div>
        <img class="sign-title" src="~static/sign/sign-title.png" alt="工程师签到抽取大奖">
        <div class="sign-address" v-if="signArr.length && CompanyId <= 0">
            <div class="sign-address-btn" v-if="!ShippingAddress" @click="isAddress=true">请填写收货地址</div>
            <div class="sign-address-btn" v-else @click="isAddress=true">已填写收货地址</div>
        </div>
        <div v-else class="sign-address">
            <div class="sign-address-btn"></div>
        </div>
        <div class="sign-day">
            <h4 class="day-integral">当前积分：{{LeftScore}}分</h4>
            <ul class="day-box" v-if="signArr.length">
                <!-- <li :class="{'no':signArr[0].IsSign}" @click="SignIndex==1&&!IsSign?onSignFun(1):''"> -->
                <li :class="{'no':signArr[0].IsSign}" @click="onSignFun(1)">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第1天</div>
                </li>
                <!-- <li :class="{'no':signArr[1].IsSign}" @click="SignIndex==2&&!IsSign?onSignFun(2):''"> -->
                <li :class="{'no':signArr[1].IsSign}" @click="onSignFun(2)">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第2天</div>
                </li>
                <!-- <li :class="{'no':signArr[2].IsSign}" @click="SignIndex==3&&!IsSign?onSignFun(3):''"> -->
                <li :class="{'no':signArr[2].IsSign}" @click="onSignFun(3)">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第3天</div>
                </li>
                <!-- <li :class="{'no':signArr[3].IsSign}" @click="SignIndex==4&&!IsSign?onSignFun(4):''"> -->
                <li :class="{'no':signArr[3].IsSign}" @click="onSignFun(4)">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第4天</div>
                </li>
                <!-- <li :class="{'no':signArr[4].IsSign}" @click="SignIndex==5&&!IsSign?onSignFun(5):''"> -->
                <li :class="{'no':signArr[4].IsSign}" @click="onSignFun(5)">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第5天</div>
                </li>
                <!-- <li :class="{'no':signArr[5].IsSign}" @click="SignIndex==6&&!IsSign?onSignFun(6):''"> -->
                <li :class="{'no':signArr[5].IsSign}" @click="onSignFun(6)">
                    <div class="item-integral">+20</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第6天</div>
                </li>
                <!-- <li :class="{'no':signArr[6].IsSign}" @click="SignIndex==7&&!IsSign?onSignFun(7):''"> -->
                <li :class="{'no':signArr[6].IsSign}" @click="onSignFun(7)">
                    <div class="item-integral">+30</div>
                    <div class="item-integral item-text">抽取大奖</div>
                    <div class="item-day">第7天</div>
                    <div class="item-icons">背景图</div>
                </li>
            </ul>
            <ul class="day-box" v-else>
                <li @click="callLogin">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第1天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第2天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第3天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第4天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+10</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第5天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+20</div>
                    <div class="item-icon">背景图</div>
                    <div class="item-day">第6天</div>
                </li>
                <li @click="callLogin">
                    <div class="item-integral">+30</div>
                    <div class="item-integral item-text">抽取大奖</div>
                    <div class="item-day">第7天</div>
                    <div class="item-icons">背景图</div>
                </li>
            </ul>
        </div>
        <Turn :activeObj="list" :UserId="UserId" :CompanyId="CompanyId" :score="LeftScore" @result="resultFun" />
        <div class="sign-rules">
            <ol>
                <li>1、本活动仅对认证工程师开放，尚未完成认证的工程师请先完成认证。</li>
                <li>2、累计签到七天即可抽奖一次</li>
                <li>3、红包奖励直接进入账户佣金余额，请前往我的佣金自行提现。</li>
                <li>4、盲盒奖励皆为实物物品，包邮寄送。抽中之后填写收货地址领取即可。</li>
                <li>5、实物物品奖品将于中奖后七个工作日内寄出。</li>
                <li>6、本活动最终解释权归工业速派所有。</li>
            </ol>
        </div>
        <van-popup v-model="isRecord" position="bottom" :close-on-click-overlay="false">
            <div class="record-box">
                <h4>中奖纪录</h4>
                <ul class="record-list">
                    <li v-for="(item,index) in DrawPrizeList" :key="index">
                        <span>{{item.DrawPrizeTime}}</span>
                        <span>{{item.PrizeName}}</span>
                    </li>
                    <li v-if="!DrawPrizeList.length"><span style="flex: 1;text-align: center;">暂无中奖记录</span></li>
                </ul>
                <div class="fooler-btn">
                    <button @click="isRecord=false">关 闭</button>
                </div>
            </div>
        </van-popup>
        <van-popup v-model="isAddress" position="top">
            <div class="address-box">
                <h4>收货地址</h4>
                <div>
                    <van-field v-model="LinkMan" name="收货人" label="收货人" placeholder="请填写收货人姓名" maxlength="20"
                        input-align="right">
                        <template slot="label">
                            <span class="address-label">收货人<i>*</i></span>
                        </template>
                    </van-field>
                    <van-field v-model="HandSet" name="联系方式" label="联系方式" type="number" placeholder="请填写联系方式"
                        maxlength="11" input-align="right">
                        <template slot="label">
                            <span class="address-label">联系方式<i>*</i></span>
                        </template>
                    </van-field>
                    <van-field v-model="Address" is-link readonly name="所在地" label="所在地" placeholder="点击选择省市"
                        @click="showArea = true" input-align="right">
                        <template slot="label">
                            <span class="address-label">所在地<i>*</i></span>
                        </template>
                    </van-field>
                    <van-popup v-model="showArea" position="bottom">
                        <van-area :area-list="areaList" columns-num="2" @confirm="onConfirmArea"
                            @cancel="showArea = false" />
                    </van-popup>
                    <van-field v-model="DetailAddress" name="详细地址" label="详细地址" placeholder="请填写详细地址" maxlength="50"
                        input-align="right">
                        <template slot="label">
                            <span class="address-label">详细地址<i>*</i></span>
                        </template>
                    </van-field>
                </div>
                <div class="fooler-btn">
                    <button @click="confirmAddress">确 定</button>
                </div>
            </div>
        </van-popup>
        <!-- 签到成功提示 -->
        <van-popup v-model="sginSuccess" class="clear-bg-color" :close-on-click-overlay="false">
            <div class="sgin-success-box">
                <div class="sgin-content">
                    <div class="add-int">+{{successTips}}积分</div>
                    <div class="add-tips">恭喜您获得{{successTips}}积分</div>
                    <button class="add-confirm" @click="sginSuccess=false">确 定</button>
                </div>
            </div>
        </van-popup>
        <!-- 谢谢参与提示框 -->
        <van-popup v-model="noThanks" class="clear-bg-color" :close-on-click-overlay="false">
            <div class="thanks-no-box">
                <button class="thanks-confirm" @click="refresh">确 定</button>
            </div>
        </van-popup>
        <!-- 中奖提示框 -->
        <van-popup v-model="yesThanks" class="clear-bg-color" :close-on-click-overlay="false">
            <div class="thanks-yes-box">
                <div class="thanks-tips thanks-tips1">{{tips1}}</div>
                <div class="thanks-tips thanks-tips2">{{tips2}}</div>
                <button v-if="tips1 == '获得2元红包' || ShippingAddress" class="thanks-confirm" @click="refresh">开 心 收 下</button>
                <button v-else class="thanks-confirm" @click="goPerfect">去 完 善</button>
            </div>
        </van-popup>
        <div class="wx-mask" v-if="isShowWX"></div>
    </div>
</template>
<script>
let u = navigator.userAgent;
let ua = navigator.userAgent.toLowerCase();
// window.addEventListener('resize', function () {
//     if (document.activeElement.tagName === 'INPUT' || document.activeElement.tagName === 'TEXTAREA') {
//         window.setTimeout(function () {
//             document.activeElement.scrollIntoViewIfNeeded()
//         }, 0)
//     }
// })
    import {
        areaList
    } from '@vant/area-data';
    import { Dialog } from 'vant';
    import Turn from '@/components/luckyDrawTurn.vue'
    import bridge from '@/common/dsbridge.js';
    import {
        ListDrawPrizeScrollInfo,
        GetSignAcivityData,
        SetSign,
        ListDrawPrizeRes,
        AddShippingAddress,
    } from "@/common/api.js";
    export default {
        components: {
            Turn
        },
        data() {
            return {
                scrollList: [], //中奖名单滚动
                left: 0,
                timer: null,
                UserId: 0,
                CompanyId: 0,
                

                IsBPOEngineer: false,

                LeftScore: 0, //积分
                SignIndex: 0, //当天可签到下标
                IsSign: true, //是否签到
                signArr: [],
                sginSuccess: false,
                successTips: 0,
                ShippingAddress: null,
                DrawPrizeList: [],

                


                noThanks: false,
                yesThanks: false,
                tips1: '',
                tips2: '',



                ActivityId: '', //活动id
                LinkMan: '',
                HandSet: '',
                Address: '',
                DetailAddress: '',
                showArea: false,
                isAddress: false, //填写地址




                isRecord: false, //中奖记录
                list: [],

                isShowWX: false,
                isAPP: false,
            }
        },
        created() {
            this.getListDrawPrizeScrollInfo()
            this.isAPP=typeof(bridge.call('getUserInfo'))==='undefined'?false:true;
            this.getUserInfo();
        },
        mounted() {
            let that = this;
            bridge.register('notifyLoginSuccess', function () {
                that.getUserInfo();
            });
            bridge.register('notifyApplyEngineerSuccess', function () {
                that.getUserInfo();
            });
        },
        computed: {
            areaList() {
                return areaList
            }
        },
        methods: {
            //未登录
            callLogin() {
                if(!this.isAPP){
                    this.openAPP();
                    return;
                }
                bridge.call('launchLogin');
            },
            openAPP: function () {
                if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                    this.isShowWX = true;
                    return;
                }
                
                if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
                    console.log('安卓');
                    window.location.href = `gongkongsupai://com.gongkong.supai?launchType=9&pageTitle=速派签到送大奖&url=${window.location.origin}?path=signDetail&interaction=true`;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        window.location.href = 'https://wx.insupai.com/download/download.html';
                        clearTimeout(this.timer);
                    }, 3000);
                } else if (u.indexOf('iPhone') > -1) {
                    console.log('苹果');
                    window.location.href = `gongkongsupai://param?launchType=9&pageTitle=速派签到送大奖&url=${window.location.origin}?path=signDetail&interaction=true`;
                    clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        window.location.href = 'https://wx.insupai.com/download/download.html';
                        clearTimeout(this.timer);
                    }, 3000);
                }
            },
            getUserInfo() {
                let that = this;
                let info = bridge.call('getUserInfo') || null;
                if (!info) return
                let getUserInfoApp = JSON.parse(info);
                let userCode = getUserInfoApp.userId;
                if (!getUserInfoApp.isLogin) {
                    // that.callLogin();
                    return;
                }
                if (userCode.indexOf('p') === 0) {
                    that.UserId = Number(userCode.substr(1));
                } else if (userCode.indexOf('e') === 0) {
                    that.CompanyId = Number(userCode.substr(1));
                }
                this.GetSignAcivityDataFun()
            },


            //跑马灯
            run() {
                if (!this.$refs.scroll) {
                    return;
                }
                let width = this.$refs.scroll.clientWidth;
                let copy = this.$refs.scroll.innerHTML;
                this.$refs.scroll.innerHTML = copy + copy;
                if (width) {
                    this.timer = setInterval(() => {
                        this.left--;
                        if (this.left < -width) this.left = 0;
                    }, 20);
                }
            },
            async getListDrawPrizeScrollInfo() {
                let res = await ListDrawPrizeScrollInfo()
                this.scrollList = res.Data
                if (this.scrollList.length) {
                    this.$nextTick(() => {
                        this.run();
                    })
                }
            },
            async GetSignAcivityDataFun() {
                this.signArr = []
                let data = {}
                data.UserId = this.UserId
                data.CompanyId = this.CompanyId
                let res = await GetSignAcivityData(data)
                this.$nextTick(() => {
                    this.LeftScore = res.Data.LeftScore
                    this.SignIndex = res.Data.SignIndex
                    this.IsSign = res.Data.IsSign
                    this.ShippingAddress = res.Data.ShippingAddress
                    this.ActivityId = res.Data.ActivityId
                    this.IsBPOEngineer = res.Data.IsBPOEngineer
                    // ture 已签到
                    // false 未签到
                    for (let i = 1; i <= 7; i++) {
                        let obj = {}
                        obj.SignIndex = i
                        obj.IsSign = false
                        this.signArr.push(obj)
                    }
                    for (let i in this.signArr) {
                        if (this.signArr[i].SignIndex < this.SignIndex) {
                            this.signArr[i].IsSign = true
                        }
                    }
                    if (this.IsSign) {
                        this.signArr[this.SignIndex - 1].IsSign = this.IsSign
                    }
                    this.list = res.Data.PrizeList
                    if (res.Data.ShippingAddress) {
                        this.LinkMan = res.Data.ShippingAddress.LinkMan
                        this.HandSet = res.Data.ShippingAddress.HandSet
                        this.Address = res.Data.ShippingAddress.Address
                        this.DetailAddress = res.Data.ShippingAddress.DetailAddress
                    }
                    
                })
            },
            // 点击查看中奖纪录
            async ListDrawPrizeResFun() {
                let data = {}
                data.UserId = this.UserId
                data.CompanyId = this.CompanyId
                let res = await ListDrawPrizeRes(data)
                if (res.Result != 1) {
                    return this.$toast(res.Message);
                }
                this.isRecord = true
                this.DrawPrizeList = res.Data || []
            },
            // 点击签到
            onSignFun(idx) {
                if(!this.IsBPOEngineer && this.UserId) {
                    Dialog.alert({
                        title: '提示',
                        message: '您还未成为工程师，请确认成为工程师',
                    }).then(() => {
                        bridge.call('jumpApplyEngineer')
                    });
                    return
                }
                if (idx == this.SignIndex && !this.IsSign) {
                    console.log('可签到')
                    let data = {}
                    data.UserId = this.UserId
                    data.CompanyId = this.CompanyId
                    SetSign(data).then(res => {
                        if (res.Result != 1) {
                            return this.$toast(res.Message);
                        }
                        this.sginSuccess = true
                        this.successTips = res.Message
                        this.GetSignAcivityDataFun()
                    })
                } else {
                    // this.$toast('请明天再来哦~')
                    console.log('请依次签到')
                }
            },
            // 抽奖返回数据
            resultFun(row) {
                this.tips1=""
                this.tips2=""
                switch (row.PrizeName) {
                    case '100元盲盒':
                        this.yesThanks = true
                        this.tips1 = '获得100元盲盒'
                        if (!this.ShippingAddress) {
                            this.tips2 = '去完善收货地址吧'
                        }
                        break;
                    case '20元盲盒':
                        this.yesThanks = true
                        this.tips1 = '获得20元盲盒'
                        if (!this.ShippingAddress) {
                            this.tips2 = '去完善收货地址吧'
                        }
                        break;
                    case '50元盲盒':
                        this.yesThanks = true
                        this.tips1 = '获得50元盲盒'
                        if (!this.ShippingAddress) {
                            this.tips2 = '去完善收货地址吧'
                        }
                        break;
                    case '2元红包':
                        this.yesThanks = true
                        this.tips1 = '获得2元红包'
                        this.tips2 = '请到佣金中查收'
                        break;
                    case '10元盲盒':
                        this.yesThanks = true
                        this.tips1 = '获得10元盲盒'
                        if (!this.ShippingAddress) {
                            this.tips2 = '去完善收货地址吧'
                        }
                        break;
                    case '谢谢参与':
                        this.noThanks = true
                        break;
                    default:
                        break;
                }
            },
            // 提交地址
            confirmAddress() {
                var self = this
                let data = {}
                data.LinkMan = this.LinkMan
                data.HandSet = this.HandSet
                data.Address = this.Address
                data.DetailAddress = this.DetailAddress
                data.ActivityId = this.ActivityId
                data.UserId = this.UserId
                data.UserType = 1
                if (!data.LinkMan) {
                    return this.$toast('收货人不能为空！')
                }
                if (!data.HandSet) {
                    return this.$toast('收货人联系方式不能为空！')
                }
                if (!data.Address) {
                    return this.$toast('所在地不能为空！')
                }
                if (!data.DetailAddress) {
                    return this.$toast('详细地址不能为空！')
                }
                AddShippingAddress(data).then(res => {
                    if (res.Result != 1) {
                        return this.$toast(res.Message)
                    }
                    this.$toast(res.Message)
                    setTimeout(function () {
                        self.isAddress = false
                        self.GetSignAcivityDataFun();
                    }, 1000)
                })
            },
            // 刷新数据
            refresh() {
                this.noThanks = false
                this.yesThanks = false
                this.GetSignAcivityDataFun();
            },
            onConfirmArea(value) {
                this.Address = value
                    .filter((item) => !!item)
                    .map((item) => item.name)
                    .join('/');
                this.showArea = false;
            },
            // 去完善
            goPerfect(){
                this.yesThanks = false
                this.isAddress = true
                this.GetSignAcivityDataFun();
            },
        }
    }
</script>
<style lang="scss">
    @import '~@/style/base.scss';

    .sign-box {
        width: 10rem;
        margin: 0 auto;
        min-height: 100vh;
        background-size: contain;
        background-color: #FF7619;
        background-position: center top;
        @include bg('~static/sign/sign-bg.png');

        .header-hint {
            padding-top: px2rem(28px);
            padding-left: px2rem(28px);
            display: flex;
            align-items: center;
            justify-content: space-between;

            .hint-list {
                color: #C6220A;
                width: px2rem(510px);
                height: px2rem(48px);
                font-size: px2rem(24px);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                border-radius: px2rem(24px);
                background-color: #FF9E8E;
                white-space: nowrap;
                padding: 0 px2rem(20px);
                box-sizing: border-box;
                position: relative;
                overflow: hidden;

                ul {
                    overflow: hidden;
                    position: absolute;
                    left: 0;
                    top: 0;
                    line-height: px2rem(48px);
                    white-space: nowrap;

                    li {
                        padding-left: px2rem(20px);
                        display: inline-block;
                        vertical-align: top;
                        padding-right: px2rem(14px);
                    }
                }
            }

            .hint-btn {
                color: #ffffff;
                text-align: center;
                width: px2rem(154px);
                height: px2rem(48px);
                font-size: px2rem(24px);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                padding-left: px2rem(18px);
                background-color: #B91D07;
                border-radius: px2rem(24px) 0 0 px2rem(24px);
            }
        }

        .sign-title {
            display: block;
            width: px2rem(528px);
            height: px2rem(228px);
            margin: px2rem(48px) auto px2rem(30px);
        }

        .sign-address {
            text-align: center;

            .sign-address-btn {
                color: #ffffff;
                position: relative;
                vertical-align: top;
                display: inline-block;
                font-size: px2rem(24px);
                line-height: px2rem(60px);
                height: px2rem(60px);
                &::before {
                    content: '';
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    position: absolute;
                    border-bottom: 1px solid #ffffff;
                }
            }
        }

        .sign-day {
            padding: 0 px2rem(38px);
            min-height: px2rem(412px);
            border-radius: px2rem(35px);
            background-color: #ffffff;
            margin: px2rem(44px) px2rem(28px) 0;

            .day-integral {
                color: #FC3916;
                font-size: px2rem(30px);
                line-height: px2rem(78px);
            }

            .day-box {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: flex-start;
                justify-content: space-between;

                li {
                    overflow: hidden;
                    text-align: center;
                    width: px2rem(130px);
                    height: px2rem(140px);
                    // background: linear-gradient(0deg, #FEF3B5 0%, #FFEF96 100%);
                    background-color: #FFEF96;
                    margin-bottom: px2rem(30px);
                    border-radius: px2rem(10px);

                    .item-integral,
                    .item-day {
                        color: #FF7619;
                        font-size: px2rem(22px);
                        line-height: px2rem(46px);
                    }

                    .item-icon {
                        font-size: 0;
                        width: px2rem(88px);
                        height: px2rem(88px);
                        background-size: contain;
                        margin: px2rem(-14px) auto 0;
                        @include bg('~static/sign/sign-integral.png');
                    }

                    .item-day {
                        margin-top: px2rem(-22px);
                    }

                    &:last-child {
                        text-align: left;
                        width: px2rem(293px);
                        padding-left: px2rem(36px);
                        position: relative;

                        .item-text {
                            line-height: px2rem(30px);
                        }

                        .item-day {
                            position: absolute;
                            bottom: 0;
                            left: px2rem(36px);
                        }

                        .item-icons {
                            font-size: 0;
                            position: absolute;
                            top: px2rem(36px);
                            right: px2rem(22px);
                            width: px2rem(124px);
                            height: px2rem(82px);
                            background-size: contain;
                            @include bg('~static/sign/sign-gift.png');
                        }
                    }
                }

                li.no {
                    background-color: #FFF6C7;

                    .item-integral,
                    .item-day {
                        color: #F9CB8D;
                    }

                    .item-icon {
                        @include bg('~static/sign/sign-on-integral.png');
                    }

                    .item-icons {
                        opacity: 0.4;
                    }
                }

            }
        }

        .sign-rules {
            color: #ffffff;
            font-size: px2rem(24px);
            line-height: px2rem(36px);
            padding: 0 px2rem(28px) px2rem(100px);
        }
    }

    .record-box,
    .address-box {
        position: relative;
        padding-bottom: px2rem(240px);
        // height: px2rem(655px);

        h4 {
            font-weight: 500;
            color: #333333;
            text-align: center;
            font-size: px2rem(32px);
            line-height: px2rem(120px);
        }

        .record-list {
            height: px2rem(360px);
            overflow-y: scroll;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: px2rem(90px);
                padding: 0 px2rem(30px);
                border-bottom: 1px solid #e5e5e5;
            }
        }

        .fooler-btn {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: px2rem(80px) px2rem(50px);

            button {
                display: block;
                color: #ffffff;
                border-radius: 2px;
                width: 100%;
                height: px2rem(80px);
                background: #F7781C;
                font-size: px2rem(28px);
            }
        }

    }

    .address-box {
        .van-cell {
            border-bottom: 1px solid #e5e5e5;

            &::after {
                border-bottom: none;
            }
        }

        .address-label {
            color: #333333;
            font-size: px2rem(28px);

            i {
                padding-left: px2rem(4px);
                color: red;
            }
        }
    }

    .clear-bg-color {
        background: none;
    }

    .sgin-success-box {
        position: relative;
        width: px2rem(542px);
        height: px2rem(508px);
        background-size: contain;
        background-position: center top;
        @include bg('~static/sign/sgin-success.png');

        .sgin-content {
            padding-top: px2rem(262px);
            text-align: center;

            .add-int {
                color: #F7781C;
                font-weight: bold;
                font-size: px2rem(46px);
                line-height: px2rem(46px);
            }

            .add-tips {
                color: #333333;
                font-size: px2rem(28px);
                line-height: px2rem(100px);
            }

            .add-confirm {
                color: #ffffff;
                width: px2rem(313px);
                height: px2rem(58px);
                font-size: px2rem(28px);
                border-radius: px2rem(29px);
                background: linear-gradient(0deg, #F7781C, #FFA144);
            }
        }
    }

    .thanks-no-box {
        position: relative;
        width: px2rem(540px);
        height: px2rem(434px);
        background-size: contain;
        background-position: center top;
        @include bg('~static/sign/no-thanks.png');

        .thanks-confirm {
            position: absolute;
            bottom: px2rem(40px);
            left: px2rem(114px);
            color: #ffffff;
            width: px2rem(313px);
            height: px2rem(58px);
            font-size: px2rem(28px);
            border-radius: px2rem(29px);
            background: linear-gradient(0deg, #FDD30C, #FFF200);
        }
    }

    .thanks-yes-box {
        position: relative;
        text-align: center;
        width: px2rem(704px);
        height: px2rem(708px);
        background-size: contain;
        background-position: center top;
        @include bg('~static/sign/thanks.png');

        .thanks-tips {
            font-size: px2rem(32px);
            color: #F7781C;
            font-weight: 500;
            line-height: px2rem(48px);
        }

        .thanks-tips1 {
            padding-top: px2rem(326px)
        }

        .thanks-confirm {
            position: absolute;
            bottom: px2rem(154px);
            left: px2rem(196px);
            color: #ffffff;
            width: px2rem(313px);
            height: px2rem(58px);
            font-size: px2rem(28px);
            border-radius: px2rem(29px);
            background: linear-gradient(0deg, #FDD30C, #FFF200);
        }
    }

    .wx-mask {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 999;
        @include bg('~static/h5/invite/cover.png');
        background-size: cover;
    }
</style>