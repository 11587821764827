<template>
    <div id="turnBox" class="turn-big-bg">
        <ul id="turnUl" class="turn-bg">
            <!-- <ul id="turnUl" :style="{ backgroundImage : 'url(' + activeObj.turntableBgc + ')'}"> -->
            <li v-for="(item,index) in activeArr" :key="index"
                :style="{webkitTransform: 'rotate(' + -item.angle + 'deg)'}">
            </li>
        </ul>
        <img src="~static/sign/turn-on.png" v-if="score>=100" alt="" @click="startPlay" class="turnBtn">
        <img src="~static/sign/turn-no.png" v-else alt="" class="turnBtn">
    </div>
</template>
<script>
    import {
        DrawPrize,
    } from "@/common/api.js";
    export default {
        props: {
            activeObj: {
                type: Array,
                default: () => []
            },
            UserId: {
                type:Number,
                default: 0
            },
            CompanyId: {
                type:Number,
                default: 0
            },
            score: {
                type: Number,
                default: 0,
            }
        },
        data() {
            return {
                pIndex: 0, // 中奖物品的下标
                rotNum: 0, // 旋转圈数基数
                time: 5000, // 旋转时间
                timer: null, // 定时器
                oTurntable: '', // 旋转圆盘背景图
                type: 0, // 0 图片 1 汉字

                activeArr: [],
                isStart: false
            }
        },
        created() {
        },
        watch:{
            activeObj: {
                handler (val) {
                    if(val) {
                        this.activeArr = this.autoRotate(val)
                    }
                },
                // 这里是关键，代表递归监听 demo 的变化
                deep: true
            }
        },
        //渲染完了
        mounted() {
            // this.activeObj = this.autoRotate(this.activeObj)
            this.oTurntable = document.querySelector('#turnUl');
            // 过度中属性用时5s
            this.oTurntable.style.webkitTransition = 'transform ' + this.time / 1000 + 's ease';
        },
        methods: {
            //自动生成角度添加到数组上
            autoRotate(arr) {
                if (arr.length) {
                    let len = arr.length;
                    let base = 325 / len;
                    arr.forEach((item, index) => {
                        // 指的是某个奖品区域的中间 : base/2
                        item.angle = 360 - (base / 2 + index * base);
                    });
                }
                return arr;
            },
            // 点击开始,请求接口抽奖
            async startPlay() {
                if(this.isStart) return
                let data = {}
                data.UserId = this.UserId
                data.CompanyId = this.CompanyId
                this.isStart = true
                let res = await DrawPrize(data)
                if(res.Result != 1) {
                    return this.$toast(res.Message);
                }
                this.$nextTick(()=> {
                    this.startBtn(res.Data)
                })
            },
            // 开始转动,通过奖项级别进行匹配:id 
            async startBtn(val) {
                const self = this
                self.activeArr.forEach((i, d) => {
                    console.log(i.Id)
                    if (i.Id === val) {
                        self.pIndex = d
                    }
                })
                // 拿到相应的角度调旋转接口
                self.startrotate(self.activeArr[self.pIndex].angle, () => {
                    self.fulfillHandle(self.activeArr[self.pIndex]);
                });
            },

            //开始旋转 angle角度  complete回调成功函数
            startrotate(angle, complete) {
                // 相应的角度 + 满圈 只是在原角度多转了几圈 360 * 6
                let rotate = 2160 * (this.rotNum + 1) + angle;
                this.oTurntable.style.webkitTransform = 'rotate(' + rotate + 'deg)';
                clearTimeout(this.timer);
                // 设置5秒后停止旋转,处理接口返回的数据
                this.timer = setTimeout(() => {
                    complete();
                    this.rotNum++;
                }, this.time);
            },
            //得奖后的处理
            fulfillHandle(prize) {
                this.$emit('result',prize)
                this.isStart = false
            },

            //自动换行
            //   autoWrap(str) {
            //     if (str.length > 5 && str.length <= 10) {
            //       str = str.substring(0, 5) + '<br/>' + str.substring(5, str.length);
            //     } else if (str.length > 10) {
            //       str = str.substring(0, 5) + '<br/>' + str.substring(5, 9) + '...';
            //     }
            //     return str;
            //   }
        }
    }
</script>
<style lang="scss" scoped>
    @import '~@/style/base.scss';

    #turnBox {
        width: px2rem(664px);
        height: px2rem(664px);
        position: relative;
        overflow: hidden;
        margin: px2rem(76px) auto px2rem(94px);
        border-radius: 50%;

        .turnBtn {
            position: absolute;
            width: px2rem(180px);
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-59%);
            overflow: hidden;
            background-repeat: no-repeat;
            background-size: 100% auto;
            z-index: 3;
        }

        ul {
            position: absolute;
            width: px2rem(536px);
            height: px2rem(536px);
            margin-left: px2rem(64px);
            margin-top: px2rem(65px);
            border-radius: 50%;
            z-index: 1;
            background-repeat: no-repeat;
            background-size: contain;
            transform-origin: center center;

            li {
                position: absolute;
                box-sizing: border-box;
                color: #7e250d;
                top: 0pc;
                left: 0px;
                width: 100%;
                height: 100%;
                line-height: 20px;
                transform-origin: 50% 50%;
            }
        }
    }

    .turn-big-bg {
        background-size: contain;
        background-color: #FF7619;
        background-position: center center;
        @include bg('~static/sign/turn-big-bg.png');

        .turn-bg {
            background-size: 100% 100%;
            background-position: center center;
            @include bg('~static/sign/turn.png');
        }
    }
</style>